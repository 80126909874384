
import { createApp } from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import "./assets/css/global.css"
import router from './router'
import  'animate.css';
import * as echarts from 'echarts';
import moment from 'moment';
import 'moment/locale/zh-cn';

moment.locale('zh-cn');

const app=createApp(App);
app.config.globalProperties.echarts = echarts;
 
app.use(router).use(Antd).mount('#app')

