import { createRouter, createWebHashHistory } from "vue-router";



const routes = [
    {
        path:"/",
        name:"home",
        component:()=>import('@/page/newPushTask.vue'),
    },
    {
        path:"/login",
        name:"login",
        component:()=>import('@/page/login.vue')
    },
    {
        path:"/index",
        name:"index",
        component:()=>import('@/page/newPushTask.vue')
    },
    {
        path:"/register",
        name:"register",
        component:()=>import('@/page/register.vue')
    },
    // {
    //     path:"/forgot",
    //     name:"forgot",
    //     component:()=>import('@/page/forgot.vue')
    // },
    {
        path:"/myPurse",
        name:"myPurse",
        component:()=>import('@/page/myPurse.vue')
    },
    {
        path:"/record",
        name:"record",
        component:()=>import('@/page/record.vue')
    },
    {
        path:"/order",
        name:"order",
        component:()=>import('@/page/order.vue')
    },
    {
        path:"/task",
        name:"task",
        component:()=>import('@/page/task.vue')
    },
    {
        path:"/pushTask",
        name:"pushTask",
        component:()=>import('@/page/newPushTask.vue')
    },
    {
        path:"/pay",
        name:"pay",
        component:()=>import('@/page/pay.vue')
    },
    {
        path:"/noLogin",
        name:"noLogin",
        component:()=>import('@/page/noLogin.vue')
    },
    {
        path:"/fail",
        name:"fail",
        component:()=>import('@/page/fail.vue')
    },
    {
        path:"/console",
        name:"console",
        component:()=>import('@/page/console.vue')
    },
    {
        path:"/announcement",
        name:"announcement",
        component:()=>import('@/page/announcement.vue')
    },
    {
        path:"/promote",
        name:"promote",
        component:()=>import('@/page/promote.vue')
    },
    {
        path:"/download",
        name:"download",
        component:()=>import('@/page/download.vue')
    },
    {
        path:"/payOrder",
        name:"payOrder",
        component:()=>import('@/page/payOrder.vue')
    },
    {
        path:"/review",
        name:"review",
        component:()=>import('@/page/review.vue')
    },
    {
        path:"/praise",
        name:"praise",
        component:()=>import('@/page/praise.vue')
    },
    {
        path:"/receipt",
        name:"receipt",
        component:()=>import('@/page/receipt.vue')
    },
    
]
const router = createRouter({
    history: createWebHashHistory(),
    routes
});
export default router;



