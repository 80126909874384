
<template>
      <router-view/>
 </template>

<script>

export default {
      setup(){
            if(window.location.hash&&window.location.hash.indexOf('?')>-1){
                  let array=window.location.hash.split('?')[1].split('&');
                  array.forEach(x=>{
                        let kv= x.split('=');
                        if(kv[0]=="token"){
                              localStorage.removeItem("token");
                              sessionStorage.removeItem("member");
                              localStorage.setItem("token",kv[1]);
                              window.location=window.location.origin;
                        }
                  })
            }
            
            return{

            };
      }
}

</script>


<style>
#app{
 height: 100%;
}

</style>
